import React from "react"
import "./header.scss"
import { Link } from "gatsby"

const Header = () => {
  return (
    <>
      <Link className="header_title" to="/details">
        <h1 className="header_title">David & Virginia</h1>
      </Link>
      <p className="header-subtitle">Wednesday 5th January 2022</p>
    </>
  )
}

export default Header
