import React from "react"
import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"
import "./index.scss"
import { graphql } from "gatsby"
import "./stay.scss"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "../utils/queryUtils"

export const query = graphql`
  query contentfulStay {
    contentfulStay {
      accomodation {
        file {
          url
        }
      }
      flower {
        file {
          url
        }
      }
      entries {
        raw
      }
      heading
    }
  }
`
const Stay = ({ data }) => {
  return (
    <div>
      <Header />
      <Navbar />
      <h2 className="page-title">Stay</h2>
      <div className="stay-container">
        <div className="stay-container_header">
          <div className="heading">{data?.contentfulStay.heading}</div>
          <img
            className="stay-container_main-image"
            src={data?.contentfulStay.accomodation.file.url}
            alt="hotel drawing"
          />
        </div>
        <div className="stay-container_text">
          {renderRichText(data?.contentfulStay.entries, options)}
        </div>
        <img
          className="stay-container_footer"
          src={data?.contentfulStay.flower.file.url}
          alt="footer of flowers"
        />
      </div>
    </div>
  )
}

export default Stay
