import React, { useState } from "react"
import { Link } from "gatsby"
import "./navbar.scss"

const Navbar = () => {
  const [showMobileNavBar, setShowMobileNavBar] = useState(false)

  return (
    <nav className={showMobileNavBar ? "navbar mobileNav" : "navbar"}>
      <button
        type="submit"
        className={showMobileNavBar ? "toggle mobileNav" : "toggle"}
        onClick={() => setShowMobileNavBar(!showMobileNavBar)}
      />
      <Link className="navbar" to="/details">
        <div className="header-logo" />
      </Link>
      <div className="header-filler" />
      <div className={showMobileNavBar ? "showOnMobile" : "navBarItems"}>
        <Link
          to="/getting-there"
          className="navbar navbar__link navbar__link-b home-margin"
        >
          Getting There
        </Link>
        <Link to="/our-story" className="navbar navbar__link navbar__link-c">
          Our Story
        </Link>
        <Link to="/gallery" className="navbar navbar__link navbar__link-a">
          Gallery
        </Link>
        <Link to="/stay" className="navbar navbar__link navbar__link-b">
          Stay
        </Link>
        <Link to="/gift" className="navbar navbar__link navbar__link-c">
          Gift
        </Link>
        <Link to="/rsvp" className="navbar navbar__link navbar__link-a">
          Rsvp
        </Link>
      </div>
    </nav>
  )
}

export default Navbar
